import request from "./axios.js";



const api = {}


api.banners = params => request.http('/appbanner/getbanners.do', 'GET', params, 1)
//获取所有设计师
api.designers = params => request.epeidaHttp('/html/access/v1/selectDesign', 'POST', params, 1)
//获取所有二维方案
api.designMasterpieces = params => request.epeidaHttp('/html/access/v1/getAllMasterpieceReview', 'POST', params, 1)
// 获取二维方案产品类型、空间、风格
api.design2DSelectParams = params => request.epeidaHttp("/html/access/v1/getAllLabel", "post", params)
//获取三维方案
api.design3D = params => request.http('/mapp/threeDimensional/list', 'GET', params, 1)
// 三维方案全部
api.design3DAll = params => request.http("/mapp/threeDimensional/selectForFirstPage", 'get', params)
//获取品牌
api.brandList = params => request.http('/appgoods/listBrand.do', 'POST', params, 1)
//获取风格
api.styleList = params => request.http('/label/getStyle.do', 'POST', params, 1)
//获取所有标签
api.labelList = params => request.http('/label/labelList.do', 'POST', params, 1)
//获取模型
api.modeList = params => request.http('/mapp/model/list', 'GET', params, 1)
// 获取首页链接导航模块 
api.bannerModual = params => request.http("/mapp/vedio/getBannerModual", "get", params)
// 获取首页视频教程 
api.bannerVedio = params => request.http("/mapp/vedio/getBannerVedio", "post", params)
//关注/取消关注设计师
api.followDesigner = params => request.epeidaHttp('/html/access/v1/addOrRemoveFollow', 'POST', params, 1)
//收藏或取消收藏二维方案
api.followSolution = params => request.epeidaHttp('/html/access/v2/design/CollectOrNot', 'POST', params, 1)
//校验密码
api.epeida = params => request.http('/appuserinfo/epeida.do', 'get', params, 1)
//收藏 collection_type  1商品 2 品牌 3模型
api.collection = params => request.http('/appcollection/collection', 'POST', params, 1)
//收藏三维方案(720VR)
api.collection3D = params => request.http("/mapp/threeDimensional/collect", 'POST', params, 1)
// 三维模型详情 
api.model3DDetail = params => request.http("/mapp/model/getInfo", 'get', params, 1)
// 三维模型下载 
api.model3DDownload = params => request.http("/mapp/model/getDownload", "get", params)
// 二维方案详情
api.design2DDetail = params => request.epeidaHttp("/html/access/v1/getProjectInfo", 'post', params)
// 二维方案详情方案包含的单品
api.design2DItem = params => request.http("/appgoods/getgoodslistinfo", 'get', params)
// 二维方案收藏或取消收藏
api.design2DCollection = params => request.epeidaHttp("/html/access/v2/design/CollectOrNot", 'post', params)
// 二维方案详情包含的单品收藏 
api.goodsItemCollection = params => request.http("/appcollection/collection.do", 'post', params)
// 给设计师发私信
api.sendPrivateLetter = params => request.http("/mapp/comment/insertComments", 'get', params)
// 获取二维方案的评论
api.getCommentItems = params => request.http("/mapp/comment/getComments", 'get', params)
// 二维方案的评论回复
api.commentReply = params => request.http("/mapp/replay/insertReplays", 'get', params)
// 二维方案的评论点赞
api.commentThumbsUp = params => request.http("/mapp/agree/agreeOrNot", 'get', params)
// 二维方案的评论举报
api.commentReport = params => request.http("/mapp/comment/report", 'get', params)
// 二维方案对设计师点赞
api.designLiked = params => request.epeidaHttp("/html/access/v1/likeOrDisLike", 'post', params)
// vip特权 
api.vipAllPlan = params => request.http("/mapp/plan/getAllPlan", "get", params)
// vip特权套餐支付
api.vipPayment = params => request.http("/mapp/memberCard/pay", "post", params)
// 检查vip特权套餐是否已支付
api.checkPayVip = params => request.http("/mapp/memberCard/checkStatus", "get", params)
// 查询用户套餐资源 
api.getUsable = params => request.http("/mapp/userPlan/getUsable", "get", params)
// 查询用户e币 
api.getUserWallet = params => request.http("/mapp/wallet/walletDetails", "post", params)
// 用户e币兑换设计资源 
api.payForDesignerWork = params => request.http("/mapp/ecoin/payForDesignerWork", "get", params)

// 获取商品信息
api.getGoodsInfo = params => request.http("/appgoods/getgoodsinfo.do", "post", params)
// 获取商品详情参数
api.getGoodsParams = params => request.http("/appgoods/goodsParameterDetails.do", "post", params)
// 获取商品参数信息
api.getParamsInfo = params => request.http("/appgoods/getratioprice.do", "post", params)
// 人气搭配商品类型
api.getPopularGoodsTypes = params => request.http("/appgoodstype/getpopulargoodsTypes.do", "post", params)
// 获取人气搭配商品
api.getPopularGoods = params => request.http("/appgoods/popularGoods.do", "post", params)
// 获取同类商品
api.getSimilarGoods = params => request.http("/appgoods/similarGoods.do", "post", params)
// 产品收藏 
api.collect = params => request.http("/appcollection/collection.do", "post", params)
// 获取品牌logo
api.getBrandLogo = params => request.http("/appgoods/getlogo.do", "post", params)
// 商品太阳码 
api.goodsSunCode = params => request.http("/mapp/share/onlyGoodsQr", "get", params)
// 获取商品SKU信息
api.goodsSkuInfo = params => request.http("/appGoodsSku/goodsSkuInfo", "get", params)
// 立即购买提交订单
api.submitOrder = params => request.http("/apppay/submitOrder.do", "post", params)
// 加入购物车
api.addToCart = params => request.http("/appshopcart/saveShopCart.do", "post", params)
// 人气搭配加入购物车
api.popularGoodsAddToCart = params => request.http("/matchinginfo/addShopCart.do", "post", params)
// 商品评价
api.goodsEvaluation = params => request.http("/appdiscuss/listDiscuss.do", "post", params)
// 订单支付
api.orderPay = params => request.http("/apppay/payOrder.do", "post", params)
// 支付宝支付
api.aliPay = params => request.http("/mapp/pay/pc/pay", "post", params)
// 检查商品订单是否已支付
api.checkOrderPay = params => request.http("/appcheck/check.do", "post", params)
// 视频教程 
api.videos = params => request.http("/mapp/vedio/getVedio", "post", params)
// 视频教程分类 
api.videoClassify = params => request.http("/mapp/dictionary/selectByParentId", 'get', params)
// 获取单个视频
api.video = params => request.http("/mapp/vedio/findVedio", "post", params)
// 订单列表 
api.orders = params => request.http("/appmyorder/allorder.do", "post", params)
// 确认收货 
api.confirmCollectGoods = params => request.http("/appmyorder/confirmCollectgoods.do", "post", params)
// 取消订单 
api.cancelOrder = params => request.http("/appmyorder/cancelOrder.do", "post", params)
// 获取用户地址 
api.getUserAddress = params => request.http("/appuseraddress/myAllUserAddress.do", "post", params)
// 保存用户地址
api.saveUserAddress = params => request.http("/appuseraddress/saveUserAddress.do", "post", params)
// 删除用户地址
api.delUserAddress = params => request.http("/appuseraddress/deleteUserAddress.do", "post", params)
// 获取用户购物车商品列表 
api.shopCartList = params => request.http("/appshopcart/shopCarts.do", "post", params)
// 增加/减少购物车某个商品的数量 
api.goodsQuantityChange = params => request.http("/appshopcart/editshopCarts.do", "post", params)
// 删除购物车中的商品
api.delShopCartItem = params => request.http("/appshopcart/deleteshopCarts.do", "post", params)
// 删除购物车中所选中的多个商品 
api.delShopCartItems = params => request.http("/appshopcart/emptyshopCarts.do", "post", params)
// 购物车商品结算
api.settlement = params => request.http("/appsettlement/settlement.do", "post", params)
// 购物车导出清单
api.exportList = params => request.http("/matchinginfo/setShare.do", "post", params)
// 套餐详情
api.setmealDetails = params => request.http('/appsetmeal/setmealDetails.do', 'POST', params)
// 套餐详情商品
api.packDetails = params => request.http('/mapp/design/all', 'POST', params)
// 套餐提交订单
api.setmealSettlement = params => request.http('/appsetmeal/setmealSettlement.do', 'POST', params)
// 套餐列表
api.setmealList = params => request.http('/appsetmeal/setmeals.do', 'POST', params)
// 获取个人信息 
api.getUserInfo = params => request.http("/appuserinfo/getuserinfo.do", "post", params)
// 获取热销商品 
api.hotSaleGoods = params => request.http("/appgoods/hotSaleGoods.do", "post", params)
// 会员签到 
api.sign = params => request.http("/appsign/sign.do", "post", params)
// 子账号
api.subAccount = params => request.http("/mapp/wallet/parentUser", "post", params)
// 获取子账号可用剩余功能信息
api.getUsableInfo = params => request.http("/mapp/userPlan/getUsableByUserId", "get", params)
// 修改子账号权限 
api.modifyPermissions = params => request.http("/mapp/plan/customFunction", "get", params)
// 获取用户E币 收入 支出 提现记录 
api.getRecorder = params => request.http("/mapp/ecoin/getRecorder", "get", params)
// 用户E币提现 
api.eCoinWithdrawal = params => request.http("/mapp/ecoin/getMoney", "get", params)
// 登陆
api.login = params => request.http("/applogin/login.do", "post", params)
// 设置价格开关
api.checkboxSet = params => request.http("/appuserinfo/checkboxSet.do", "post", params)
// 获取省市区
api.cityList = params => request.http("/appregion/nextlist.do", "post", params)
// 注册获取验证码
api.appRegister = params => request.http("/appsendmessage/sendSms/appRegister.do", "post", params)
// 注册获取随机数
api.getRandomNum = params => request.http("/appsendmessage/getRandomNum.do", "post", params)
// 立即注册
api.register = params => request.http("/appregiseter/register.do", "post", params)
// 重置密码
api.resetPsd = params => request.http("/applogin/resetPsd.do", "post", params)
// 获取重置密码验证码
api.appForgetPsd = params => request.http("/appsendmessage/sendSms/appForgetPsd.do", "post", params)
// 商城首页获取风格
api.indexGetStyle = params => request.http("/label/getStyle.do", "post", params)
// 商城获取banner
api.getbanners = params => request.http("/appbanner/getbanners.do", "post", params)
// 商城首页获取720
api.getVr = params => request.http("/appdesigner/get720.do", "post", params)
// 商城首页获取套餐列表
api.getIndexTaocanList = params => request.http("/appsetmeal/taocanList.do", "post", params)
// 商城首页空间产品列表数据
api.getHomeSuperiors = params => request.http("/apphome/getHomeSuperiors.do", "post", params)
// 商城首页饰品数据
api.getTasted = params => request.http("/appgoods/getgoods.do", "post", params)
// 商城首页新闻数据
api.getNew = params => request.http("/news/getNew.do", "post", params)
// 商城臻选家具数据
api.choosingFurnitureData = params => request.http("/appgoods/getgoods.do", "post", params)
// 商城严选软装数据
api.getbrands = params => request.http("/appbrand/getbrands.do", "post", params)
// 商城优选品牌本地馆数据
api.getlocal = params => request.http("/appbrand/getlocal.do", "post", params)
// 商城品牌信息
api.getbrandinfo = params => request.http("/appbrand/getbrandinfo.do", "post", params)
// 商城品牌详情数据
api.getbrandgoods = params => request.http("/appbrand/getbrandgoods.do", "post", params)
// 商城品牌收藏
api.brandCollection = params => request.http("/appcollection/collection.do", "post", params)
// 获取手机验证码 
api.getPhoneCode = params => request.http("/appsendmessage/sendSms/editPsd.do", "post", params)
// 修改密码
api.changePassword = params => request.http("/appuserinfo/editpassword.do", "post" ,params)
// 会员签到的数据和状态
api.signStatus = params => request.http("/mapp/ecoin/getSignStatus", "get", params)
// 会员签到获取e币
api.everydaySign = params => request.http("/mapp/ecoin/everydaySign", "get", params)
// 会员签到明细 
api.signDetails = params => request.http("/mapp/ecoin/getSignDetails", "get", params)
// 会员商品收藏列表 
api.goodsCollectionList = params => request.http("/appcollection/goodsCollectionList.do", "post", params)
// 会员品牌收藏列表 
api.bandCollectionList = params => request.http("/appcollection/bandCollectionList.do", "post", params)
// 会员模型收藏列表 
api.modelCollectionList = params => request.http("/appcollection/modelCollectionList", "post", params)
// 会员二维方案收藏列表 
api.twoDimensionCollect = params => request.epeidaHttp("/html/access/v2/design/getCollection", "post", params)
// 会员作品中心
api.myTwoDimension = params => request.epeidaHttp("/html/access/v1/getMyTwoDimension", "post", params)
// 个人中心用户换头 
api.changeAvatar = params => request.http("/mapp/plan/swapHeadUrl", "get", params)
// 获取主推商品 
api.mainProducts = params => request.http("/goods/goodsNow.do", "post", params)
// 获取产品风格 
api.goodsStyles = params => request.http("/appgoodstype/getstyles.do", "post", params)
// 商城热销排行类型
api.hotSellTypeLabel = params => request.http("/label/labelList.do", "post", params)
// 商城热销商品
api.hotSellGoods = params => request.http("/appgoods/goodsNewgoodslist.do", "post", params)
// 商城新品推荐 
api.newProduct = params => request.http("/appgoods/goodsNewgoodslist.do", "post", params)
// 获取商品
api.getGoodsList  = params => request.http('/appgoods/getgoods.do', 'post', params)
// 商城活动专区 
api.discount = params => request.http("/appgoods/goodsDiscountlist.do", "post", params)
// 商城所有商品分类
api.labelList = params => request.http("/label/labelList.do", "post", params)
// 浏览记录 
api.browseRecords = params => request.http("/mapp/browse/getBrowse", "get", params)
// 获取用户所有地址 
api.allUserAddress = params => request.http("/appuseraddress/myAllUserAddress.do", "post", params)
// 获取结算商品信息 
api.submitOrderPage = params => request.http("/apppay/submitOrderPage.do", "post", params)
// 保存用户地址 
api.saveUserAddress = params => request.http("/appuseraddress/saveUserAddress.do", "post", params)
// 用户提交订单 
api.userSubmitOrder = params => request.http("/apppay/submitEditOrder.do", "post", params)
// 用户下载记录 
api.downdloadRecord = params => request.http("/mapp/resourceRecord/getAllResourceRecord", "get", params)
// 有模型品牌
api.modelBrand = params => request.http("/brand/listBrandHaveModel", "get", params)
// 有商品品牌 
api.goodsBrand = params => request.http("/brand/listBrandHaveGoods", "get", params)
// 获取微信登录appId
api.appId = params => request.http("/mapp/openUser/getAppid", "get", params)
// 微信登录 
api.wechatLogin = params => request.http("/mapp/openUser/callback", "get", params)
// 绑定微信
api.wechatBind = params => request.http("/mapp/openUser/bindUserIdAppid", "get", params)
// 生成微信登录二维码 
api.loginErcode = params => request.http("/mapp/openUser/getLoginErcode", "get", params)
// 个人中心获取用户资料 
api.getUserData = params => request.http("/appregiseter/getUserInfo", "get", params)
// 个人中心修改用户资料  
api.updateUserData = params => request.http("/appregiseter/updateUserInfo", "get", params)
// 字典接口 
api.selectByKey = params => request.http("/mapp/dictionary/selectByKey", "get", params)
// 商品管理列表 
api.commodityList = params => request.http("/mapp/operation/getGoodsList", "get", params)
// 商品管理添加商品  true表示数据使用json格式
api.commodityAdd = params => request.http("/mapp/operation/addGoods", "post", params, true)
// 会员支付（杉德支付）
api.memberCardPay = params => request.http("/mapp/memberCard/pay", "get", params)
// 订单详情
api.orderDetails = params => request.http("/appmyorder/orderDetails.do", "post", params)
// 删除订单
api.deleteOrder = params => request.http("/appmyorder/deleteOrder.do", "post", params)

// 灯无忧产品分类
api.deng51GoodsClassify = params => request.deng51http("/buyer/goods/noDecode/getGoodsTypeList", "get", params)
// 灯无忧产品列表
api.deng51GoodsList = params => request.deng51http("/buyer/goods/noDecode/searchList", "get", params)
// 灯无忧产品详情
api.deng51GoodsDetail = params => request.deng51http("/buyer/goods/noDecode/getTypeGoodsById/", "get", params, 0, 1)

// 精项好物版块
// 拎包套餐
api.bagSetmeals = params => request.http("/appsetmeal/bagSetmeals.do", "post", params)
// 拎包单品/明星同款 拎包单品列表  必传参数:bag_type:1  可传参数:color:'#ffffff' 明星同款列表  必传参数:star_type:1 
api.getGoods = params => request.http("/appgoods/getgoods", "post", params)
// 获取拎包单品所有颜色
api.getColors = params => request.http("/color/list", "get", params)
// 获取场景购场景分类
api.getProgramme = params => request.http("/label/getProgramme","get",params)
// 获取场景购画布
api.canvasSearch = params => request.http("/canvas/canvasSearch", "get",params)
// 获取画布详情
api.canvasInfo = params => request.http("/canvas/canvasInfo","get",params)
//方案
api.getAllMasterpieceReview = params => request.epeidaHttp("/html/access/v1/getAllMasterpieceReview","post",params)
//上传图片
api.ossUpload = params => request.postFiles("/ossupload/uploadImg","post",params)
//显示筛选条件
api.getSearch = params => request.http("/searchFilter/getSearch","get",params)
//获取店铺信息
api.getShopInfo = params => request.http("/mapp/shop/details","get",params)
//获取店铺信息
api.getShopInfoByUserId = params => request.http("/mapp/shop/info","get",params)


export default api;
