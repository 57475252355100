const global = {
	state: {
		token: localStorage.getItem("token"),
		alert: {
			message: null,
			confirm: null
		},
		computer: true,
		router: null,
		userInfo: null
	},
	mutations: {
		setAlert(state, payload) {
			state.alert = payload;
		},
		setToken(state, payload) {
			state.token = payload;
		},
		setComputer(state,payload){
			state.computer = payload;
		},
		setRouter(state,payload){
			state.router = payload;
		},
		setUserInfo(state,payload){
			state.userInfo = payload
		}
	}
}

export default global
