const dump = {}
import Vue from 'vue'
import router from '../router'
import epeida_url_config from '../api/epeidaUrlConfig.js'
import member_platform_config from '../api/memberPlatformConfig.js'
import url from '@/api/config'
import { urlRenovation } from '../api/config'
import {post} from "axios"
// type: 1
dump.link = (item) => {

  if (!item || !item.link || item.link == '' || item.link == "#") {
    return false;
  }
  if (item.type == 1) {
    let d = {
      path: item.link
    }
    if (item.params) {
      d.query = item.params;
    }
    router.push(d);
  } else if (item.type == 2) {
    // window.location.href=item.link;
    var ua = navigator.userAgent.toLowerCase();
    let s = ua.match(/version\/([\d.]+).*safari/) ? 1 : 0;
    //如果是safari浏览器
    if (s == 1) {
      window.location.href = item.link;
      return false;
    }
    if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(ua)) {
      //跳转移动端页面
      window.location.href = item.link;
      return false;
    }
    window.open(item.link);
  } else if (item.type == 3) {
    // 新窗口打开页面
    let ua = navigator.userAgent.toLowerCase(),
      s = ua.match(/version\/([\d.]+).*safari/) ? 1 : 0;
    const link = {
      path: item.link
    }
    if (item.params) {
      link.query = item.params
    }
    const { href } = router.resolve(link);
    //如果是safari浏览器
    if (s == 1) {
      // window.location.href = href;
      //首先打开一个新页面
      let winOpen = window.open("", "_blank");
      post(url + "/label/labelList.do").then(() => {
        let link = href;
        //这里使用setTimeout非常重要，没有将无法实现
        //原因是window.open会中断正在执行的进程，这样能保证其它代码执行完成再执行这个。
        setTimeout(function() { 
          //改变页面的location
          winOpen.location = link; 
        }, 100);
      }, err => {
        console.error(err.msg);
      })
      return false;
    }
    if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(ua)) {
      //跳转移动端页面
      router.push(href);
      return false;
    }
    window.open(href, "_blank");
  } else {
    window.location.href = item.link;
  }
}
dump.checkLogin = () => {
  let token = localStorage.getItem("token");
  if (!token) {
    Vue.prototype.$confirm('您未登录系统请登录后再进行相关操作！', '温馨提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      let item = {
        type: 1,
        link: "/login"
      }
      dump.link(item);
    }).catch(() => {});
    
    return false;
  }
  return true;
}
dump.toEPeida = (res) => {
  //跳转e配搭
  if (res.status == 103) {
    localStorage.removeItem("token");
  } else {
    let link = epeida_url_config + '/html/v1/redirect/login?phone=' + localStorage.getItem(
      'phone') + '&token=' + localStorage.getItem("token") + '&role_id=' + localStorage.getItem(
      'role_id'); // + `${res.projectId ? '&project_id=' + res.projectId : ''}`
    let data = {
      type: 2,
      link: link
    }
    dump.link(data);
  }
}
dump.toMemberPlatform = (res) => {
  //跳转e配搭
  if (res.status == 103) {
    localStorage.removeItem("token");
  } else {
    let link = member_platform_config + '/#?token=' + localStorage.getItem("token") + "&&shopId=" + localStorage.getItem("shopId"); // + `${res.projectId ? '&project_id=' + res.projectId : ''}`
    let data = {
      type: 2,
      link: link
    }
    dump.link(data);
  }
}
//跳转到装修神器
dump.toRenovation = () =>{
  let link = urlRenovation;
  let data = {
    type: 2,
    link: link
  }
  dump.link(data);
}
export default dump;
