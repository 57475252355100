import i18n from '../i18n'
import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router);



let routes = [{
  path: '/',
  name: 'home',
  component: () => import("@/view/index/index"),
  meta: {
    title: i18n.t("homeTitle")
  }
},
// {
//   path: '/to-*',
//   name: 'home',
//   component: () => import("@/view/index/index"),
//   meta: {
//     title: i18n.t("homeTitle")
//   }
// },
{
  path: '/tMode',
  name: 'tMode',
  component: () => import("@/view/index/tMode"),
  meta: {
    title: i18n.t("tModeTitle")
  }
},
// VIP特权
{
  path: '/vipprivilege',
  name: 'vipprivilege',
  component: () => import("@/view/vip-privilege/vipPrivilege"),
  meta: {
    title: i18n.t("vipPrivilegeTitle")
  }
},
// vip套餐选择支付方式
{
  path: "/pay/vipPaymentMethod",
  name: "vipPaymentMethod",
  component: () => import("@/view/pay/vipPaymentMethod/vipPaymentMethod"),
  meta: {
    title: i18n.t("vipPaymentMethodTitle")
  }
},
// vip套餐支付
{
  path: "/pay/vipPay",
  name: "vipPay",
  component: () => import("@/view/pay/vipPay/vipPay"),
  meta: {
    title: i18n.t("vipPayTitle")
  }
},
// 二维方案详情
{
  path: "/twodimensionalprogramme",
  name: "twodimensionalprogramme",
  component: () => import("@/view/two-dimensional-programme/twoDimensionalProgramme"),
  meta: {
    title: i18n.t("twoDimensionalProgrammeTitle")
  }
},
{
  path: "/planeScheme",
  name: "planeScheme",
  component: () => import("@/view/index/planeScheme"),
  meta: {
    title: i18n.t("planeSchemeTitle")
  }
},
{
  path: "/tool",
  name: "tool",
  component: () => import("@/view/index/tool"),
  meta: {
    title: i18n.t("toolTitle")
  }
},
{
  path: "/threeDimensional",
  name: "threeDimensional",
  component: () => import("@/view/index/threeDimensional"),
  meta: {
    title: i18n.t("threeDimensionalTitle")
  }
},
{
  path: "/usercenter",
  name: "usercenter",
  component: () => import("@/components/userCenter"),
  children: [
    // 个人中心
    {
      path: "",
      name: "usercenter.personalCenter",
      component: () => import("@/view/userCenter/personalCenter/personalCenter"),
      meta: {
        title: i18n.t("personalCenterTitle")
      }
    },
    // 个人资料
    {
      path: "userInfo",
      name: "usercenter.userInfo",
      component: () => import("@/view/userCenter/userInfo/userInfo"),
      meta: {
        title: i18n.t("userInfoTitle")
      }
    },
    // 账户安全
    {
      path: "accountSecurity",
      name: "usercenter.accountSecurity",
      component: () => import("@/view/userCenter/accountSecurity/accountSecurity"),
      meta: {
        title: i18n.t('accountSecurityTitle')
      }
    },
    // 收货地址
    {
      path: "receivingAddress",
      name: "usercenter.receivingAddress",
      component: () => import("@/view/userCenter/receivingAddress/receivingAddress"),
      meta: {
        title: i18n.t("receivingAddressTitle")
      }
    },
    // 会员签到
    {
      path: "signin",
      name: "usercenter.signin",
      component: () => import("@/view/userCenter/signIn/signIn"),
      meta: {
        title: i18n.t('signInTitle')
      }
    },
    // 查询用户套餐资源
    {
      path: "packageResources",
      name: "usercenter.packageResources",
      component: () => import("@/view/userCenter/packageResources/packageResources"),
      meta: {
        title: i18n.t('packageResourcesTitle')
      }
    },
    // 资产管理 --> 收支明细
    {
      path: "assetDetails",
      name: "usercenter.assetDetails",
      component: () => import("@/view/userCenter/assetManagement/assetDetails/assetDetails"),
      meta: {
        title: i18n.t('assetDetailsTitle')
      }
    },
    // 资产管理 --> 我的分润
    {
      path: "myShare",
      name: "usercenter.myShare",
      component: () => import("@/view/userCenter/assetManagement/myShare/myShare"),
      meta: {
        title: i18n.t('myShareTitle')
      }
    },
    // 资产管理 --> 我的e币
    {
      path: "eCoin",
      name: "usercenter.eCoin",
      component: () => import("@/view/userCenter/assetManagement/eCoin/eCoin"),
      meta: {
        title: i18n.t('eCoinTitle')
      }
    },
    // 资产管理 --> 兑换提现
    {
      path: "exchange",
      name: "usercenter.exchange",
      component: () => import("@/view/userCenter/assetManagement/exchange/exchange"),
      meta: {
        title: i18n.t('exchangeTitle')
      }
    },
    // 订单管理
    {
      path: "orderManagement",
      name: "usercenter.orderManagement",
      component: () => import("@/view/userCenter/orderManagement/orderManagement"),
      meta: {
        title: i18n.t("orderManagementTitle")
      }
    },
    // 订单详情
    {
      path: "orderDetail",
      name: "usercenter.orderDetail",
      component: () => import("@/view/userCenter/orderDetail/orderDetail"),
      meta: {
        title: i18n.t("orderDetailTitle")
      }
    },
    // 子账号管理 --> 子账号列表
    {
      path: "subAccountList",
      name: "usercenter.subAccountList",
      component: () => import("@/view/userCenter/subAccountManagement/subAccountList/subAccountList"),
      meta: {
        title: i18n.t("subAccountListTitle")
      }
    },
    // 子账号管理 --> 子账号详情
    {
      path: "subAccountDetail",
      name: "usercenter.subAccountDetail",
      component: () => import("@/view/userCenter/subAccountManagement/subAccountDetail/subAccountDetail"),
      meta: {
        title: i18n.t("subAccountDetailTitle")
      }
    },
    // 商品管理 --> 商品列表
    {
      path: "commodityList",
      name: "usercenter.commodityList",
      component: () => import("@/view/userCenter/commodityList/commodityList"),
      meta: {
        title: i18n.t('commodityListTitle')
      }
    },
    // 商品管理 --> 新增商品
    {
      path: "addCommodity",
      name: "usercenter.addCommodity",
      component: () => import("@/view/userCenter/addCommodity/addCommodity"),
      meta: {
        title: i18n.t('addCommodityTitle')
      }
    },
    // 作品中心
    {
      path: 'worksCenter',
      name: 'usercenter.worksCenter',
      component: () => import('@/view/userCenter/worksCenter/worksCenter'),
      meta: {
        title: i18n.t('worksCenterTitle')
      }
    },
    // 我的收藏
    {
      path: 'collectionRecord',
      name: 'usercenter.collectionRecord',
      component: () => import('@/view/userCenter/collectionRecord/collectionRecord'),
      meta: {
        title: i18n.t('collectionRecordTitle')
      }
    },
    // 消息中心
    {
      path: 'messageCenter',
      name: 'usercenter.messageCenter',
      component: () => import('@/view/userCenter/messageCenter/messageCenter'),
      meta: {
        title: i18n.t('messageCenterTitle')
      }
    },
    // 下载记录
    {
      path: 'downloadRecord',
      name: 'usercenter.downloadRecord',
      component: () => import('@/view/userCenter/downloadRecord/downloadRecord'),
      meta: {
        title: i18n.t('downloadRecordTitle')
      }
    },
    // 浏览记录
    {
      path: 'browseRecords',
      name: 'usercenter.browseRecords',
      component: () => import('@/view/userCenter/browseRecords/browseRecords'),
      meta: {
        title: i18n.t('browseRecordsTitle')
      }
    }
  ]
},

// 用户地址
{
  path: "/usercenter/userAddress",
  name: "userAddress",
  component: () => import("@/view/userCenter/userAddress/userAddress"),
  meta: {
    title: i18n.t("receivingAddressTitle")
  }
},
// 购物车
{
  path: "/usercenter/shopCart",
  name: "shopCart",
  component: () => import("@/view/userCenter/shopCart/shopCart"),
  meta: {
    title: i18n.t("shopCartTitle")
  }
},
// 3D模型详情
{
  path: "/model3Ddetail",
  name: "model3Ddetail",
  component: () => import("@/view/model3DDetail/model3DDetail"),
  meta: {
    title: i18n.t("model3DDetailTitle")
  }
},
// 商品详情
{
  path: "/product/details",
  name: "productDetails",
  component: () => import("@/view/product/details/details"),
  meta: {
    title: i18n.t("productDetailsTitle")
  }
},
// 商品订单选择支付方式
{
  path: "/pay/orderPaymentMethod",
  name: "orderPaymentMethod",
  component: () => import("@/view/pay/orderPaymentMethod/orderPaymentMethod"),
  meta: {
    title: i18n.t("orderPaymentMethodTitle")
  }
},
// 商品订单支付
{
  path: "/pay/orderPay",
  name: "orderPay",
  component: () => import("@/view/pay/orderPay/orderPay"),
  meta: {
    title: i18n.t("orderPayTitle")
  }
},
// 视频教程
{
  path: "/videoTutorial",
  name: "videoTutorial",
  component: () => import("@/view/index/videoTutorial"),
  meta: {
    title: i18n.t("videoTutorialTitle")
  }
},
// 视频播放页
{
  path: "/videoTutorial/videoPlay",
  name: "videoPlay",
  component: () => import("@/view/index/videoPlay"),
  meta: {
    title: i18n.t("videoPlayTitle")
  }
},
// 套餐详情
{
  path: '/packageDetails',
  name: 'packageDetails',
  component: () => import("@/view/packageDetails/packageDetails"),
  meta: {
    title: '套餐详情'
  }
},
// 套餐列表
{
  path: '/packageList',
  name: 'packageList',
  component: () => import("@/view/packageList/packageList"),
  meta: {
    title: '套餐列表'
  }
},
// 子账号管理
{
  path: '/usercenter/subAccountManagement',
  name: 'subAccountManagement',
  component: () => import("@/view/userCenter/subAccountManagement/subAccountManagement"),
  meta: {
    title: i18n.t("subAccountManagementTitle")
  }
},
// 资产管理
{
  path: '/usercenter/assetManagement',
  name: 'assetManagement',
  component: () => import("@/view/userCenter/assetManagement/assetManagement"),
  meta: {
    title: i18n.t('assetManagementTitle')
  }
},

{
  path: "/test",
  name: "test",
  component: () => import("@/view/userCenter/test")
},
{
  path: "/test1",
  name: "test1",
  component: () => import("@/view/userCenter/test1")
},
{
  path: "/test2",
  name: "test2",
  component: () => import("@/view/userCenter/test2")
},
{
  path: "/print",
  name: "print",
  component: () => import("@/view/print")
},
// 登录
{
  path: '/login',
  name: 'login',
  component: () => import("@/view/login/login"),
  meta: {
    title: i18n.t('loginTitle')
  }
},
// 微信登录
{
  path: '/wechatLogin',
  name: 'wechatLogin',
  component: () => import("@/view/login/wechatLogin"),
  meta: {
    title: i18n.t('wechatLoginTitle')
  }
},
// 注册
{
  path: '/registered',
  name: 'registered',
  component: () => import("@/view/registered/registered"),
  meta: {
    title: '注册'
  }
},
// 忘记密码
{
  path: '/forgotPassword',
  name: 'forgotPassword',
  component: () => import("@/view/forgotPassword/forgotPassword"),
  meta: {
    title: '忘记密码'
  }
},
// 商城首页
{
  path: '/mallIndex',
  name: 'mallIndex',
  component: () => import("@/view/mall/index/index"),
  meta: {
    title: '商城首页'
  }
},
// 装修神器
{
  path: '/room',
  name: 'room',
  component: () => import("@/view/room/room"),
  meta: {
    title: '装修'
  }
},
// 臻选家具
{
  path: '/choosingFurniture',
  name: 'choosingFurniture',
  component: () => import("@/view/mall/choosingFurniture/choosingFurniture"),
  meta: {
    title: '臻选家具'
  }
},
// 严选软装
{
  path: '/softOutfit',
  name: 'softOutfit',
  component: () => import("@/view/mall/softOutfit/softOutfit"),
  meta: {
    title: '严选软装'
  }
},
// 优选品牌
{
  path: '/optimizingBrand',
  name: 'optimizingBrand',
  component: () => import("@/view/mall/optimizingBrand/optimizingBrand"),
  meta: {
    title: '优选品牌'
  }
},
// 现货专区
{
  path: "/cashGoods",
  name: "cashGoods",
  component: () => import("@/view/mall/cashGoods/cashGoods"),
  meta: {
    title: '现货专区'
  }
},
// 热销排行
{
  path: "/hotSell",
  name: "hotSell",
  component: () => import("@/view/mall/hotSell/hotSell"),
  meta: {
    title: '热销排行'
  }
},
// 新品推荐
{
  path: "/newProduct",
  name: "newProduct",
  component: () => import("@/view/mall/newProduct/newProduct"),
  meta: {
    title: '新品推荐'
  }
},
// 活动专区
{
  path: "/discount",
  name: "discount",
  component: () => import("@/view/mall/discount/discount"),
  meta: {
    title: "活动专区"
  }
},
// 品牌详情
{
  path: '/brandDetails',
  name: 'brandDetails',
  component: () => import("@/view/mall/brandDetails/brandDetails"),
  meta: {
    title: '品牌详情'
  }
},
// 全部分类
{
  path: '/search',
  name: 'search',
  component: () => import("@/view/mall/search/search"),
  meta: {
    title: '全部分类'
  }
},
{
  path: '/searchImg',
  name: 'searchImg',
  component: () => import("@/view/mall/search/searchImg"),
  meta: {
    title: '图片搜索'
  }
},
// 商品管理
{
  path: '/usercenter/commodityManagement',
  name: 'commodityManagement',
  component: () => import("@/view/userCenter/commodityManagement/commodityManagement"),
  meta: {
    title: i18n.t('commodityManagementTitle')
  }
},
// 订单结算
{
  path: '/orderSettlement',
  name: 'orderSettlement',
  component: () => import('@/view/mall/orderSettlement/orderSettlement'),
  meta: {
    title: i18n.t('orderSettlementTitle')
  }
},
// 物流配送
{
  path: '/logisticsDistribution',
  name: 'logisticsDistribution',
  component: () => import('@/view/mall/logisticsDistribution/logisticsDistribution'),
  meta: {
    title: i18n.t('logisticsDistributionTitle')
  }
},
// 场景购
{
  path: '/scenePurchase',
  name: 'scenePurchase',
  component: () => import('@/view/mall/scenePurchase/scenePurchase'),
  meta: {
    title: i18n.t('scenePurchaseTitle')
  }
},
// 场景购详情页
{
  path: '/sceneInfo',
  name: 'sceneInfo',
  component: () => import('@/view/mall/sceneInfo/sceneInfo'),
  meta: {
    title: i18n.t('scenePurchaseTitle')
  }
},
// 灯无忧产品列表
{
  path: '/deng51',
  name: 'deng51',
  component: () => import('@/view/mall/deng51/deng51'),
  meta: {
    title: "灯无忧"
  }
},
// 灯无忧产品详情
{
  path: '/deng51goodsdetail',
  name: 'deng51goodsdetail',
  component: () => import('@/view/mall/deng51/details'),
  meta: {
    title: "灯无忧商品详情"
  }
},
// 灯无忧购物车
{
  path: '/deng51shopcart',
  name: 'deng51shopcart',
  component: () => import('@/view/mall/deng51/shopCart'),
  meta: {
    title: '灯无忧购物车'
  }
},
// 精选好物
{
  path: '/carefullyChosenGoods',
  name: 'carefullychosengoods',
  component: () => import('@/view/carefullyChosenGoods/index/index'),
  meta: {
    title: '拎包好物'
  }
},
// 拎包套餐
{
  path: '/bagCheck',
  name: 'bagcheck',
  component: () => import('@/view/carefullyChosenGoods/bagCheck/bagCheck'),
  meta: {
    title: '拎包套餐'
  }
},
// 拎包套餐详情
{
  path: '/bagCheckDetail',
  name: 'bagcheckdetail',
  component: () => import('@/view/carefullyChosenGoods/bagCheckDetail/bagCheckDetail'),
  meta: {
    title: '拎包套餐详情'
  }
},
// 拎包单品
{
  path: '/bagCheckSingle',
  name: 'bagchecksingle',
  component: () => import('@/view/carefullyChosenGoods/bagCheckSingle/bagCheckSingle'),
  meta: {
    title: '拎包单品'
  }
},
// 明星同款
{
  path: '/starSameStyle',
  name: 'starsamestyle',
  component: () => import('@/view/carefullyChosenGoods/starSameStyle/starSameStyle'),
  meta: {
    title: '明星同款'
  }
},

{
  path: '/to-*',
  name: 'home',
  component: () => import("@/view/index/index"),
  meta: {
    title: i18n.t("homeTitle")
  }
},
{
  path: '/to-*/tMode',
  name: 'to.tMode',
  component: () => import("@/view/index/tMode"),
  meta: {
    title: i18n.t("tModeTitle")
  }
},
// VIP特权
{
  path: 'vipprivilege',
  name: 'vipprivilege',
  component: () => import("@/view/vip-privilege/vipPrivilege"),
  meta: {
    title: i18n.t("vipPrivilegeTitle")
  }
},
// vip套餐选择支付方式
{
  path: "pay/vipPaymentMethod",
  name: "vipPaymentMethod",
  component: () => import("@/view/pay/vipPaymentMethod/vipPaymentMethod"),
  meta: {
    title: i18n.t("vipPaymentMethodTitle")
  }
},
// vip套餐支付
{
  path: "pay/vipPay",
  name: "vipPay",
  component: () => import("@/view/pay/vipPay/vipPay"),
  meta: {
    title: i18n.t("vipPayTitle")
  }
},
// 二维方案详情
{
  path: "/twodimensionalprogramme",
  name: "twodimensionalprogramme",
  component: () => import("@/view/two-dimensional-programme/twoDimensionalProgramme"),
  meta: {
    title: i18n.t("twoDimensionalProgrammeTitle")
  }
},
{
  path: "/planeScheme",
  name: "planeScheme",
  component: () => import("@/view/index/planeScheme"),
  meta: {
    title: i18n.t("planeSchemeTitle")
  }
},
{
  path: "/tool",
  name: "tool",
  component: () => import("@/view/index/tool"),
  meta: {
    title: i18n.t("toolTitle")
  }
},
{
  path: "/threeDimensional",
  name: "threeDimensional",
  component: () => import("@/view/index/threeDimensional"),
  meta: {
    title: i18n.t("threeDimensionalTitle")
  }
},
{
  path: "/usercenter",
  name: "usercenter",
  component: () => import("@/components/userCenter"),
  children: [
    // 个人中心
    {
      path: "",
      name: "usercenter.personalCenter",
      component: () => import("@/view/userCenter/personalCenter/personalCenter"),
      meta: {
        title: i18n.t("personalCenterTitle")
      }
    },
    // 个人资料
    {
      path: "userInfo",
      name: "usercenter.userInfo",
      component: () => import("@/view/userCenter/userInfo/userInfo"),
      meta: {
        title: i18n.t("userInfoTitle")
      }
    },
    // 账户安全
    {
      path: "accountSecurity",
      name: "usercenter.accountSecurity",
      component: () => import("@/view/userCenter/accountSecurity/accountSecurity"),
      meta: {
        title: i18n.t('accountSecurityTitle')
      }
    },
    // 收货地址
    {
      path: "receivingAddress",
      name: "usercenter.receivingAddress",
      component: () => import("@/view/userCenter/receivingAddress/receivingAddress"),
      meta: {
        title: i18n.t("receivingAddressTitle")
      }
    },
    // 会员签到
    {
      path: "signin",
      name: "usercenter.signin",
      component: () => import("@/view/userCenter/signIn/signIn"),
      meta: {
        title: i18n.t('signInTitle')
      }
    },
    // 查询用户套餐资源
    {
      path: "packageResources",
      name: "usercenter.packageResources",
      component: () => import("@/view/userCenter/packageResources/packageResources"),
      meta: {
        title: i18n.t('packageResourcesTitle')
      }
    },
    // 资产管理 --> 收支明细
    {
      path: "assetDetails",
      name: "usercenter.assetDetails",
      component: () => import("@/view/userCenter/assetManagement/assetDetails/assetDetails"),
      meta: {
        title: i18n.t('assetDetailsTitle')
      }
    },
    // 资产管理 --> 我的分润
    {
      path: "myShare",
      name: "usercenter.myShare",
      component: () => import("@/view/userCenter/assetManagement/myShare/myShare"),
      meta: {
        title: i18n.t('myShareTitle')
      }
    },
    // 资产管理 --> 我的e币
    {
      path: "eCoin",
      name: "usercenter.eCoin",
      component: () => import("@/view/userCenter/assetManagement/eCoin/eCoin"),
      meta: {
        title: i18n.t('eCoinTitle')
      }
    },
    // 资产管理 --> 兑换提现
    {
      path: "exchange",
      name: "usercenter.exchange",
      component: () => import("@/view/userCenter/assetManagement/exchange/exchange"),
      meta: {
        title: i18n.t('exchangeTitle')
      }
    },
    // 订单管理
    {
      path: "orderManagement",
      name: "usercenter.orderManagement",
      component: () => import("@/view/userCenter/orderManagement/orderManagement"),
      meta: {
        title: i18n.t("orderManagementTitle")
      }
    },
    // 订单详情
    {
      path: "orderDetail",
      name: "usercenter.orderDetail",
      component: () => import("@/view/userCenter/orderDetail/orderDetail"),
      meta: {
        title: i18n.t("orderDetailTitle")
      }
    },
    // 子账号管理 --> 子账号列表
    {
      path: "subAccountList",
      name: "usercenter.subAccountList",
      component: () => import("@/view/userCenter/subAccountManagement/subAccountList/subAccountList"),
      meta: {
        title: i18n.t("subAccountListTitle")
      }
    },
    // 子账号管理 --> 子账号详情
    {
      path: "subAccountDetail",
      name: "usercenter.subAccountDetail",
      component: () => import("@/view/userCenter/subAccountManagement/subAccountDetail/subAccountDetail"),
      meta: {
        title: i18n.t("subAccountDetailTitle")
      }
    },
    // 商品管理 --> 商品列表
    {
      path: "commodityList",
      name: "usercenter.commodityList",
      component: () => import("@/view/userCenter/commodityList/commodityList"),
      meta: {
        title: i18n.t('commodityListTitle')
      }
    },
    // 商品管理 --> 新增商品
    {
      path: "addCommodity",
      name: "usercenter.addCommodity",
      component: () => import("@/view/userCenter/addCommodity/addCommodity"),
      meta: {
        title: i18n.t('addCommodityTitle')
      }
    },
    // 作品中心
    {
      path: 'worksCenter',
      name: 'usercenter.worksCenter',
      component: () => import('@/view/userCenter/worksCenter/worksCenter'),
      meta: {
        title: i18n.t('worksCenterTitle')
      }
    },
    // 我的收藏
    {
      path: 'collectionRecord',
      name: 'usercenter.collectionRecord',
      component: () => import('@/view/userCenter/collectionRecord/collectionRecord'),
      meta: {
        title: i18n.t('collectionRecordTitle')
      }
    },
    // 消息中心
    {
      path: 'messageCenter',
      name: 'usercenter.messageCenter',
      component: () => import('@/view/userCenter/messageCenter/messageCenter'),
      meta: {
        title: i18n.t('messageCenterTitle')
      }
    },
    // 下载记录
    {
      path: 'downloadRecord',
      name: 'usercenter.downloadRecord',
      component: () => import('@/view/userCenter/downloadRecord/downloadRecord'),
      meta: {
        title: i18n.t('downloadRecordTitle')
      }
    },
    // 浏览记录
    {
      path: 'browseRecords',
      name: 'usercenter.browseRecords',
      component: () => import('@/view/userCenter/browseRecords/browseRecords'),
      meta: {
        title: i18n.t('browseRecordsTitle')
      }
    }
  ]
},

// 用户地址
{
  path: "/usercenter/userAddress",
  name: "userAddress",
  component: () => import("@/view/userCenter/userAddress/userAddress"),
  meta: {
    title: i18n.t("receivingAddressTitle")
  }
},
// 购物车
{
  path: "/usercenter/shopCart",
  name: "shopCart",
  component: () => import("@/view/userCenter/shopCart/shopCart"),
  meta: {
    title: i18n.t("shopCartTitle")
  }
},
// 3D模型详情
{
  path: "/model3Ddetail",
  name: "model3Ddetail",
  component: () => import("@/view/model3DDetail/model3DDetail"),
  meta: {
    title: i18n.t("model3DDetailTitle")
  }
},
// 商品详情
{
  path: "/product/details",
  name: "productDetails",
  component: () => import("@/view/product/details/details"),
  meta: {
    title: i18n.t("productDetailsTitle")
  }
},
// 商品订单选择支付方式
{
  path: "/pay/orderPaymentMethod",
  name: "orderPaymentMethod",
  component: () => import("@/view/pay/orderPaymentMethod/orderPaymentMethod"),
  meta: {
    title: i18n.t("orderPaymentMethodTitle")
  }
},
// 商品订单支付
{
  path: "/pay/orderPay",
  name: "orderPay",
  component: () => import("@/view/pay/orderPay/orderPay"),
  meta: {
    title: i18n.t("orderPayTitle")
  }
},
// 视频教程
{
  path: "/videoTutorial",
  name: "videoTutorial",
  component: () => import("@/view/index/videoTutorial"),
  meta: {
    title: i18n.t("videoTutorialTitle")
  }
},
// 视频播放页
{
  path: "/videoTutorial/videoPlay",
  name: "videoPlay",
  component: () => import("@/view/index/videoPlay"),
  meta: {
    title: i18n.t("videoPlayTitle")
  }
},
// 套餐详情
{
  path: '/packageDetails',
  name: 'packageDetails',
  component: () => import("@/view/packageDetails/packageDetails"),
  meta: {
    title: '套餐详情'
  }
},
// 套餐列表
{
  path: '/packageList',
  name: 'packageList',
  component: () => import("@/view/packageList/packageList"),
  meta: {
    title: '套餐列表'
  }
},
// 子账号管理
{
  path: '/usercenter/subAccountManagement',
  name: 'subAccountManagement',
  component: () => import("@/view/userCenter/subAccountManagement/subAccountManagement"),
  meta: {
    title: i18n.t("subAccountManagementTitle")
  }
},
// 资产管理
{
  path: '/usercenter/assetManagement',
  name: 'assetManagement',
  component: () => import("@/view/userCenter/assetManagement/assetManagement"),
  meta: {
    title: i18n.t('assetManagementTitle')
  }
},

{
  path: "/test",
  name: "test",
  component: () => import("@/view/userCenter/test")
},
{
  path: "/test1",
  name: "test1",
  component: () => import("@/view/userCenter/test1")
},
{
  path: "/test2",
  name: "test2",
  component: () => import("@/view/userCenter/test2")
},
{
  path: "/print",
  name: "print",
  component: () => import("@/view/print")
},
// 登录
{
  path: '/login',
  name: 'login',
  component: () => import("@/view/login/login"),
  meta: {
    title: i18n.t('loginTitle')
  }
},
// 微信登录
{
  path: '/wechatLogin',
  name: 'wechatLogin',
  component: () => import("@/view/login/wechatLogin"),
  meta: {
    title: i18n.t('wechatLoginTitle')
  }
},
// 注册
{
  path: '/registered',
  name: 'registered',
  component: () => import("@/view/registered/registered"),
  meta: {
    title: '注册'
  }
},
// 忘记密码
{
  path: '/forgotPassword',
  name: 'forgotPassword',
  component: () => import("@/view/forgotPassword/forgotPassword"),
  meta: {
    title: '忘记密码'
  }
},
// 商城首页
{
  path: 'mallIndex',
  name: 'mallIndex',
  component: () => import("@/view/mall/index/index"),
  meta: {
    title: '商城首页'
  }
},
// 装修神器
{
  path: '/room',
  name: 'room',
  component: () => import("@/view/room/room"),
  meta: {
    title: '装修'
  }
},
// 臻选家具
{
  path: '/choosingFurniture',
  name: 'choosingFurniture',
  component: () => import("@/view/mall/choosingFurniture/choosingFurniture"),
  meta: {
    title: '臻选家具'
  }
},
// 严选软装
{
  path: '/softOutfit',
  name: 'softOutfit',
  component: () => import("@/view/mall/softOutfit/softOutfit"),
  meta: {
    title: '严选软装'
  }
},
// 优选品牌
{
  path: '/optimizingBrand',
  name: 'optimizingBrand',
  component: () => import("@/view/mall/optimizingBrand/optimizingBrand"),
  meta: {
    title: '优选品牌'
  }
},
// 现货专区
{
  path: "/cashGoods",
  name: "cashGoods",
  component: () => import("@/view/mall/cashGoods/cashGoods"),
  meta: {
    title: '现货专区'
  }
},
// 热销排行
{
  path: "/hotSell",
  name: "hotSell",
  component: () => import("@/view/mall/hotSell/hotSell"),
  meta: {
    title: '热销排行'
  }
},
// 新品推荐
{
  path: "/newProduct",
  name: "newProduct",
  component: () => import("@/view/mall/newProduct/newProduct"),
  meta: {
    title: '新品推荐'
  }
},
// 活动专区
{
  path: "/discount",
  name: "discount",
  component: () => import("@/view/mall/discount/discount"),
  meta: {
    title: "活动专区"
  }
},
// 品牌详情
{
  path: '/brandDetails',
  name: 'brandDetails',
  component: () => import("@/view/mall/brandDetails/brandDetails"),
  meta: {
    title: '品牌详情'
  }
},
// 全部分类
{
  path: '/search',
  name: 'search',
  component: () => import("@/view/mall/search/search"),
  meta: {
    title: '全部分类'
  }
},
{
  path: '/searchImg',
  name: 'searchImg',
  component: () => import("@/view/mall/search/searchImg"),
  meta: {
    title: '图片搜索'
  }
},
// 商品管理
{
  path: '/usercenter/commodityManagement',
  name: 'commodityManagement',
  component: () => import("@/view/userCenter/commodityManagement/commodityManagement"),
  meta: {
    title: i18n.t('commodityManagementTitle')
  }
},
// 订单结算
{
  path: '/orderSettlement',
  name: 'orderSettlement',
  component: () => import('@/view/mall/orderSettlement/orderSettlement'),
  meta: {
    title: i18n.t('orderSettlementTitle')
  }
},
// 物流配送
{
  path: '/logisticsDistribution',
  name: 'logisticsDistribution',
  component: () => import('@/view/mall/logisticsDistribution/logisticsDistribution'),
  meta: {
    title: i18n.t('logisticsDistributionTitle')
  }
},
// 场景购
{
  path: '/scenePurchase',
  name: 'scenePurchase',
  component: () => import('@/view/mall/scenePurchase/scenePurchase'),
  meta: {
    title: i18n.t('scenePurchaseTitle')
  }
},
// 场景购详情页
{
  path: '/sceneInfo',
  name: 'sceneInfo',
  component: () => import('@/view/mall/sceneInfo/sceneInfo'),
  meta: {
    title: i18n.t('scenePurchaseTitle')
  }
},
// 灯无忧产品列表
{
  path: '/deng51',
  name: 'deng51',
  component: () => import('@/view/mall/deng51/deng51'),
  meta: {
    title: "灯无忧"
  }
},
// 灯无忧产品详情
{
  path: '/deng51goodsdetail',
  name: 'deng51goodsdetail',
  component: () => import('@/view/mall/deng51/details'),
  meta: {
    title: "灯无忧商品详情"
  }
},
// 灯无忧购物车
{
  path: '/deng51shopcart',
  name: 'deng51shopcart',
  component: () => import('@/view/mall/deng51/shopCart'),
  meta: {
    title: '灯无忧购物车'
  }
},
// 精选好物
{
  path: '/carefullyChosenGoods',
  name: 'carefullychosengoods',
  component: () => import('@/view/carefullyChosenGoods/index/index'),
  meta: {
    title: '拎包好物'
  }
},
// 拎包套餐
{
  path: '/bagCheck',
  name: 'bagcheck',
  component: () => import('@/view/carefullyChosenGoods/bagCheck/bagCheck'),
  meta: {
    title: '拎包套餐'
  }
},
// 拎包套餐详情
{
  path: '/bagCheckDetail',
  name: 'bagcheckdetail',
  component: () => import('@/view/carefullyChosenGoods/bagCheckDetail/bagCheckDetail'),
  meta: {
    title: '拎包套餐详情'
  }
},
// 拎包单品
{
  path: '/bagCheckSingle',
  name: 'bagchecksingle',
  component: () => import('@/view/carefullyChosenGoods/bagCheckSingle/bagCheckSingle'),
  meta: {
    title: '拎包单品'
  }
},
// 明星同款
{
  path: '/starSameStyle',
  name: 'starsamestyle',
  component: () => import('@/view/carefullyChosenGoods/starSameStyle/starSameStyle'),
  meta: {
    title: '明星同款'
  }
}
]

let base = "";
// if(cookie.getCookie("netSuffix") != null){
//   base = "/to-" + cookie.getCookie("netSuffix")
// }
console.log("base为：" + base)
console.log(base.split("/")[1])
console.log("href", window.location.pathname)

var reg = /to-[a-z]{5,10}/;
var current_url = location.href;
var result = current_url.match(reg);
//检测到个性域名
if (result && result[0]) {
  base = '/' + result[0];
}
let router = new Router({
  mode: 'history',
  base: base,
  routes
});


export default router

