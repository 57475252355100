import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './global/Element'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import $ from 'jquery'
import 'bootstrap'
import BootstrapVue from 'bootstrap-vue'
import axios from 'axios'
import store from './vuex/store'

import request from './api/axios.js'
import api from './api/api.js'
import url from './api/config.js'
import utils from './util/util.js'
import i18n from './i18n'
import waterfall from 'vue-waterfall2'
import Bus from './util/eventBus'
import cookie from "./util/cookie.js"

import './assets/font/iconfont.js'
import './assets/font/iconfont.css'
import './assets/font/icon.css'

Vue.prototype.$axios = axios
Vue.prototype.$ = $;
Vue.prototype.$request = request
Vue.prototype.$api = api
Vue.prototype.$url = url
Vue.prototype.$utils = utils
Vue.prototype.$bus = new Bus()
Vue.prototype.cookie = cookie
Vue.use(waterfall)
Vue.use(BootstrapVue)
Vue.config.productionTip = false
Vue.mixin({
  mounted() {
    const shopName = cookie.getCookie("shopName")
    this.$nextTick(() => {
      // console.log("我是全局混入", shopName)

      if (shopName != null) {
        this.replaceTextEl(this.$el)
      }
    })
  },
  methods: {
    replaceTextEl(el) {
      const shopName = cookie.getCookie("shopName")
      // var elements = el.getElementsByTagName("*")
      // console.log("elements", elements)
      // 获取所有子节点
      const allNodes = el.childNodes;
      // console.log("所有节点", allNodes)
      // const textToReplace = "美易搭"
      allNodes.forEach(node => {
        if (node.textContent.includes("美易搭")) {
          console.log("美易搭", node)
          const textFrom = "美易搭"
          if (node.nodeType === Node.TEXT_NODE) {
            // 如果节点是文本节点，则替换文本中的内容
            node.textContent = node.textContent.replace(new RegExp(textFrom, 'g'), shopName);
          } else if (node.nodeType === Node.ELEMENT_NODE) {
            console.log("元素节点", node)
            // 如果节点是元素节点，则递归遍历子元素
            this.replaceText(node, textFrom, shopName);
          }
        }
        if (node.textContent.includes("装点e家")) {
          console.log("装点e家", node)
          const textFrom = "装点e家"
          if (node.nodeType === Node.TEXT_NODE) {
            console.log("文字节点", node)
            // 如果节点是文本节点，则替换文本中的内容
            node.textContent = node.textContent.replace(new RegExp(textFrom, 'g'), shopName);
          } else if (node.nodeType === Node.ELEMENT_NODE) {
            console.log("装点e家子节点", node)
            // 如果节点是元素节点，则递归遍历子元素
            this.replaceText(node, textFrom, shopName);
          }
        }
      });
    },

    replaceText(el, textFrom, shopName) {
      console.log("参数", el, textFrom, shopName)
      // 同上，但是这里是递归函数，所以需要单独实现
      const childNodes = el.childNodes;
      childNodes.forEach(node => {
        if (node.nodeType === Node.TEXT_NODE) {
          node.textContent = node.textContent.replace(new RegExp(textFrom, 'g'), shopName);
          console.log("遍历文字节点", node)
        }
        else if (node.nodeType === Node.ELEMENT_NODE) {
          console.log("遍历子节点", node)
          this.replaceText(node, textFrom, shopName);
        }
      });
    }

  }
})



router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  let shopPath = ""
  if(router.options.base){
    shopPath = router.options.base
  }
  if(shopPath){
    api.getShopInfo({ shopName: shopPath.split("-")[1] }).then(res => {
      cookie.setCookieClose({ netSuffix: shopPath.split("-")[1] })
      cookie.setCookieClose({ shopName: res.data.shopName })
      cookie.setCookieClose({ shopId: res.data.shopId})
      cookie.setCookieClose({ headerLogo: res.data.headerLogo })
      cookie.setCookieClose({ footerLogo: res.data.footerLogo })
      cookie.setCookieClose({ telephone: res.data.phone })
      cookie.setCookieClose({ companyName: res.data.companyName})
      console.log("店铺信息", res.data)
    })
  }
  next()
})



new Vue({
  render: h => h(App),
  router: router,
  i18n,
  store
}).$mount('#app')
